import {
  CAPTCHA_CHALLENGE_HTTP_HEADER,
  CAPTCHA_HTTP_HEADER,
  IStorage,
} from '@nosinovacao/nosid-mfe-common';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  AUTHORIZE_STORAGE_KEY,
  DEVICE_ID_STORAGE_KEY,
  KEEP_SESSION_STORAGE_KEY,
  LOGIN_HINT_STORAGE_KEY,
  SESSION_TOKEN_STORAGE_KEY,
} from '@/constants';
import {
  AuthorizeParams,
  LogoutParams,
  OneStepLogin,
  PasswordRequest,
  ResponseAction,
} from '@/models';
import { useNavigation } from '@/navigation';
import { getEndpointByVersion, useValidateUsernameBase } from './base-hooks';
import { useNosidSignInMutation } from './mutation.hook';
import { useAppContext } from '@/context';

export function useAuthorize() {
  const {
    utilsService,
    config,
    enterpriseContextService,
    sessionStorageService,
    localStorageService,
    templates,
    loginHintContextService,
  } = useAppContext();

  const isSinglePageLogin = (proxyVersion = '') => {
    return config.APIConfiguration.SingleStepLoginValidProxyVersions.some(
      (version) => version === proxyVersion,
    );
  };

  const { navigateTo, navigateToError } = useNavigation();
  const { mutate } = useNosidSignInMutation(
    async (axios, params: Record<string, string>) => {
      const q: Record<string, any> = {};
      Object.keys(params).forEach(
        (k) => (q[k] = decodeURIComponent(params[k] || '')),
      );
      const body: AuthorizeParams = {
        client_id: q.client_id ?? templates.clientTemplate.ClientId,
        response_type: q.response_type,
        scope: q.scope,
        redirect_uri: utilsService.sanitizeUrl(
          q.redirect_uri,
          templates.clientTemplate.PortalLink ?? '',
        ),
        state: q.state,
        nonce: q.nonce,
        auto_login: q.auto_login,
        prompt: q.prompt,
        login_hint:
          q.login_hint || loginHintContextService.authorizeLoginHintContext(),
        code_challenge: q.code_challenge,
        code_challenge_method: q.code_challenge_method,
        apigee_org: q.apigee_org,
        apigee_env: q.apigee_env,
        apigee_host: q.apigee_host,
        proxy_basepath: q.proxy_basepath,
        proxy_version: q.proxy_version,
        sign_up: q.sign_up === 'true',
        single_page_login: isSinglePageLogin(q.proxy_version),
      };
      enterpriseContextService.enterpriseContext = q.enterprise_context ?? '';
      loginHintContextService.loginHintContext = q.login_hint ?? '';
      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.Authorize,
            config,
            templates,
          ),
          body,
          {
            headers: {
              Authorization: `Bearer ${
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                localStorageService.getString(SESSION_TOKEN_STORAGE_KEY) ||
                sessionStorageService.getString(SESSION_TOKEN_STORAGE_KEY)
              }`,
            },
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        const params = new URLSearchParams(window.location.search);
        const loginHint = params.get('login_hint')?.replace(/ /g, '+');
        if (loginHint) data.LoginHint = loginHint;
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
      retry: (_, err) => {
        const hasSessionToken =
          localStorageService.getString(SESSION_TOKEN_STORAGE_KEY) === 'true' ||
          sessionStorageService.getString(SESSION_TOKEN_STORAGE_KEY) === 'true';
        if (err.response?.status === 403 && hasSessionToken) {
          localStorageService.remove(SESSION_TOKEN_STORAGE_KEY);
          sessionStorageService.remove(SESSION_TOKEN_STORAGE_KEY);
          return false;
        }
        return false;
      },
    },
  );

  return mutate;
}

export function useLogout() {
  const {
    sessionStorageService,
    localStorageService,
    config,
    utilsService,
    templates,
  } = useAppContext();
  const { mutate, isLoading } = useNosidSignInMutation(
    async (axios, _: string) => {
      const deviceId = localStorageService.getString(DEVICE_ID_STORAGE_KEY);
      const keepSession = sessionStorageService.getString(
        KEEP_SESSION_STORAGE_KEY,
      );
      const body: LogoutParams = {
        SessionToken:
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          (localStorageService.getString(SESSION_TOKEN_STORAGE_KEY) ||
            sessionStorageService.getString(SESSION_TOKEN_STORAGE_KEY)) ??
          '',
      };

      localStorageService.clear();

      sessionStorageService.remove(KEEP_SESSION_STORAGE_KEY);

      clearLoginHintStorage(sessionStorageService);

      if (!keepSession || (keepSession && JSON.parse(keepSession) !== true)) {
        sessionStorageService.remove(SESSION_TOKEN_STORAGE_KEY);
      }

      if (deviceId) {
        localStorageService.setString(DEVICE_ID_STORAGE_KEY, deviceId);
      }
      return await axios
        .post(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.Logout,
            config,
            templates,
          ),
          body,
        )
        .then((res) => res.data);
    },
    {
      loader: false,
      onResponse: (_, input) => {
        utilsService.redirect(input, templates.clientTemplate.PortalLink ?? '');
      },
    },
  );
  return { logout: mutate, isLoading };
}

export function clearLoginHintStorage(sessionStorageService: IStorage) {
  const authorizeSession = {
    ...sessionStorageService.getObject<Record<string, string | string[]>>(
      AUTHORIZE_STORAGE_KEY,
    ),
  };

  sessionStorageService.remove(LOGIN_HINT_STORAGE_KEY);
  sessionStorageService.remove(AUTHORIZE_STORAGE_KEY);
  if (Object.keys(authorizeSession).length !== 0) {
    sessionStorageService.setObject(AUTHORIZE_STORAGE_KEY, {
      ...authorizeSession,
      login_hint: '',
    });
  }
}

export function useValidateUsername(
  onResponse?: (data: ResponseAction) => void,
) {
  const { mutateAsync } = useValidateUsernameBase(
    'username',
    'AuthorizeUsername',
    false,
    onResponse,
  );

  return mutateAsync;
}

export function useValidatePassword(
  onResponse?: (data: ResponseAction) => void,
) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync } = useNosidSignInMutation(
    async (
      axios,
      input: { password: string; honeyPot?: string; tokenChallenge?: string },
    ) => {
      const body: PasswordRequest = {
        Password: input.password,
        Username: input.honeyPot ? input.honeyPot : undefined,
      };
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      if (input.tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = input.tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.('password');
      }

      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            config.APIConfiguration.AA.Endpoint.AuthorizePassword,
            config,
            templates,
          ),
          body,
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse?.(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return mutateAsync;
}

export function useLoginOneStep(onResponse?: (data: ResponseAction) => void) {
  const { config, navigationStateService, templates } = useAppContext();
  const { navigateTo, navigateToError } = useNavigation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync } = useNosidSignInMutation(
    async (
      axios,
      input: {
        isSocialSingleStep: boolean;
        username: string;
        password: string;
        honeyPot?: string;
        tokenChallenge?: string;
      },
    ) => {
      const body: OneStepLogin = {
        Password: input.password,
        Username: input.username,
        Email: input.honeyPot ? input.honeyPot : undefined,
      };
      const headers: Record<string, string | undefined> = {
        Authorization: `Bearer ${
          navigationStateService.getCurrentState()?.Token
        }`,
      };

      if (input.tokenChallenge) {
        headers[CAPTCHA_HTTP_HEADER] = input.tokenChallenge;
        headers[CAPTCHA_CHALLENGE_HTTP_HEADER] = 'true';
      } else {
        headers[CAPTCHA_HTTP_HEADER] = await executeRecaptcha?.('loginOneStep');
      }
      return await axios
        .post<ResponseAction>(
          getEndpointByVersion(
            input.isSocialSingleStep
              ? config.APIConfiguration.AA.Endpoint
                  .SocialNetworkAssociateSingleStepLogin
              : config.APIConfiguration.AA.Endpoint.OneStepLogin,
            config,
            templates,
          ),
          body,
          {
            headers,
          },
        )
        .then((response) => response.data);
    },
    {
      loader: false,
      onResponse: (data) => {
        onResponse?.(data);
        if (data.Error) {
          navigateToError(data);
        } else {
          navigateTo(data);
        }
      },
    },
  );

  return mutateAsync;
}
